//Scripts
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import arrangements from '../therapy/arrangements.js';
import SpellCheckTextareaAutosize from './../utils/SpellCheckTextareaAutosize.js';
import therapies from './../therapy/therapies.js';
import TimeDifferenceToNow from '../utils/timeDifferenceToNow.js';
import { fullDayDateAndTime, calculateAge, isToday } from '../utils/dateTimeUtils.js'
import { stringFormat } from '../calendar/calendarLogic.js';

//CSS
import './journal.css';

function Journal({ loginUser, logout }) {
    const { t } = useTranslation();
    const [journals, setJournals] = useState(null);
    const isFetchingRef = useRef(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookingId = searchParams.get('bookingId');

    const loadJournals = async () => {
        const postData = {
            "mod_type": "load",
            "booking_id": bookingId
        };
        try {
            const journalUrl = `/api/therapist/journal.php`;
            const response = await axios.post(journalUrl, postData);
            setJournals(response.data);
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;

        loadJournals();
    }, []);
    
    const handleTextareaChange = (journalId, prop, value) => {
        fetch('/api/therapist/journal.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mod_type: 'update',
                journal_id: journalId,
                property: prop,
                value: value
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log('Update successful');
            } else {
                console.error('Update failed', data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    if (!journals) {
        return <h2>{t("data-loading")}</h2>
    }

    if (journals.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    let pastBookingCount = 0;
    const props = [ "history", "treatment", "notes", "diagnosis" ];
    const journalContent = journals.map((journal) => {
        const bookingDate = new Date(journal["date"]);
        const bookingUpComming = new Date() < bookingDate;
        if (!bookingUpComming) {
            pastBookingCount++;
        }

        const fields = props.map((prop) => {
            const field = pastBookingCount > 2 ? (
                    <div className='journal-readonly-description'>{journal[prop]}</div>
                ) : (
                <SpellCheckTextareaAutosize
                    name={prop}
                    defaultValue={journal[prop]}
                    maxLength={512}
                    onChange={(e) => handleTextareaChange(journal.id, prop, e.target.value)}
                />);
            return (
                <div className='journal-item' key={"journal-" + journal.id + "-" + prop}>
                    <p className='journal-item-description'>{t('journal-' + prop)}</p>
                    {field}
                </div>
            );
        })

        let therapyText;
        if (journal["cancel_date"]) {
            therapyText = t('booking-prop-cancel_date') + " " + fullDayDateAndTime(t, new Date(journal["cancel_date"]));
        } else {
            const therapy = therapies.find((therapy) => therapy.id === journal["therapy_id"]);
            const arrangement = arrangements.find((arrangement) => arrangement.id === journal["arrangement_id"]);
            therapyText = t(therapy.labelKey) + ", " + arrangement.durationInMinutes + " " + t('time-minutes');
        }

        const therapyHeadline = " (" + fullDayDateAndTime(t, bookingDate) + ")";
        return (
            <div>
                {isToday(bookingDate) ? (
                    <h4 className='journal-header'>{t('booking-today')}</h4>
                ) : null}
                <div className='journal-header'>{therapyText}</div>
                <div className='journal-header'><TimeDifferenceToNow date={bookingDate} pastOrFutureIndication={true}></TimeDifferenceToNow>{therapyHeadline}</div>
                <div key={"journal-" + journal.id} className='journal-container'>
                    {fields}
                </div>
            </div>
        );
    });

    const anyJournal = journals[0];
    let userDisplay = anyJournal["email"];
    if (anyJournal["firstname"] && anyJournal["lastname"]) {
        userDisplay = anyJournal["firstname"] + " " + anyJournal["lastname"];
    }
    if (anyJournal["birthday"]) {
        userDisplay += " (" + calculateAge(anyJournal["birthday"]) + ")";
    }

    const healthConditions = [
        "blood_pressure", "medication", "surgery", "illness", 
        "allergies", "implants", "health_problems", "general_remarks"
    ].reduce((acc, prop) => {
        if (!anyJournal[prop]) {
            return acc;
        }
        acc.push(
            <div key={"health-prop" + prop} className="health-prop">
                <span className="health-label">{t('label-' + prop)}</span>
                <span className="health-value">{anyJournal[prop]}</span>
            </div>
        );
        return acc;
    }, []);

    return (
        <div>
            {healthConditions && healthConditions.length > 0 ? (
                <React.Fragment>
                    <h2>{t('health-form-health-header') + " " + userDisplay}</h2>
                    <div className='health-condition-container'>
                        {healthConditions}
                    </div>
                </React.Fragment>
            ) : null}
            <h2>{t('journal-headline')}</h2>
            {journalContent}
        </div>
    );
}

export default Journal;
